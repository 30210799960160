<template>
  <a-layout-header>
    <aRow type="flex" justify="space-between">
      <aCol :span="$root.windowWidth > 1480 ? 17 : 15"> </aCol>
      <aCol :span="$root.windowWidth > 1480 ? 7 : 9" class="user-profile">
        <aRow type="flex" justify="end">
          <aCol>
            <a-popconfirm
              title="Tem certeza que deseja limpar o cache?"
              ok-text="Sim"
              cancel-text="Não"
              @confirm="clearCache"
            >
              <img
                class="c-pointer"
                src="@/assets/images/dashboard/clear-cache.png"
                alt="img"
              />
            </a-popconfirm>
          </aCol>
          <aCol>
            <a-popover
              placement="bottom"
              trigger="click"
              width="300px"
              class="notifications"
            >
              <template slot="content">
                <ListNotifications
                  v-if="!loadingNotifications"
                  @getNotifications="getNotifications"
                  :loading="loadingNotifications"
                />
              </template>
              <template slot="title">
                <aRow type="flex" justify="space-between">
                  <aCol> Notificações </aCol>

                  <aCol>
                    <span
                      class="reload-btn c-pointer"
                      @click="reloadNotifications"
                    >
                      <a-tooltip>
                        <template slot="title"> Atualizar </template>
                        <img
                          src="@/assets/images/contract/sync.png"
                          alt="reload"
                          width="14"
                        />
                      </a-tooltip>
                    </span>
                  </aCol>
                </aRow>
              </template>
              <div class="action">
                <a-badge :count="totalUnread">
                  <img src="@/assets/images/dashboard/sino.png" alt="sino" />
                </a-badge>
              </div>
            </a-popover>
          </aCol>
          <aCol
            style="
              padding-left: 10px;
              padding-right: 0;
              width: 252px;
              text-align: left;
            "
          >
            <a-dropdown :trigger="['click']">
              <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
                <a-avatar
                  class="mr-10"
                  size="large"
                  icon="user"
                  v-if="$store.state.userData.meta.avatar"
                  :src="$store.state.userData.meta.avatar"
                />

                <a-avatar
                  class="mr-10"
                  size="large"
                  icon="user"
                  v-if="!$store.state.userData.meta.avatar"
                  :src="avatar"
                />
                <span
                  class="username relative"
                  style="width: 180px; display: inline-block"
                >
                  <span style="top: -4px; position: relative"
                    >Olá {{ $store.state.userData.first_name }}!</span
                  >

                  <span
                    style="
                      font-size: 10px;
                      display: block;
                      line-height: 10px;
                      position: absolute;
                      left: 1px;
                      top: 38px;
                      color: #aaa;
                    "
                  >
                    <a-icon type="clock-circle" />
                    Renova em:
                    {{ theExpirationDate }}
                  </span>
                </span>
              </a>

              <a-menu class="profile-dropdown" slot="overlay">
                <a-menu-item key="1" @click="$router.push(`/profile`)">
                  <a-icon type="user" />
                  Meu perfil
                </a-menu-item>

                <a-menu-item key="3" @click="logOut()">
                  <a-icon type="logout" />
                  Sair
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </aCol>
        </aRow>
      </aCol>
    </aRow>
  </a-layout-header>
</template>

<script>
import avatar from "@/assets/images/dashboard/user-avatar.png";
import ListNotifications from "../../notifications/ListNotifications.vue";
import headerMixins from "./mixins/headerMixins";

export default {
  name: "SystemHeaderDesktop",
  props: {
    theExpirationDate: String,
  },
  mixins: [headerMixins],
  components: { ListNotifications },
  data() {
    return {
      avatar,
    };
  },
  mounted() {
    this.getNotifications();
    setInterval(() => {
      this.getNotifications();
    }, 20000);
  },
  methods: {
    reloadNotifications() {
      this.loadingNotifications = true;

      setTimeout(() => {
        this.getNotifications();
      }, 100);
    },
  },
};
</script>

<style lang="sass" scoped>
#newest-ntofication
  position: absolute
  right: 20px
  top: 80px
  background: linear-gradient( 180deg, rgb(255, 220, 235) 0%, rgb(255, 247, 223) 100%)
  z-index: 10
  width: 300px
  box-shadow: 0 0 10px #aaa
  border-radius: 10px
  padding: 0
  .head
    position: relative
    padding: 8px 10px
    border-bottom: 1px solid #fbc6da
    line-height: 1.4
    .close
      position: absolute
      top: 3px
      cursor: pointer
      right: 8px
      z-index: 10
    .txt
      color: #be4178
      font-size: 12px
    .title
      color: #444
      font-size: 14px
      font-weight: 600
      line-height: 1
  .body
    font-size: 14px
    padding: 15px
    cursor: pointer
  .body p
    background: #000
    margin-bottom: 5px !important
</style>

<style>
#img-uploaded {
  max-width: 100%;
}
</style>
